import React, {Component} from 'react';
import r01 from '../img/i_r01.png';

class Roca extends Component {

    positionRoca = {};

    state = {
        windowHeight: window.innerHeight,
        windowWidth: window.innerHeight,
        posicionX: undefined,
        posicionY: undefined
    }

    handleResize = () => this.setState(
    {
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        posicionX: Math.round(Math.random() * (130 - 0) + 0),
        posicionY: Math.round(Math.random() * (130 - 0) + 0)
    }
    );

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render(){
        let rotation = Math.round(Math.random() * (360 - 45) + 45);
        let scale = Math.round(Math.random() * (30 - 10) + 10);

        this.positionRoca = {
            width: scale+'px',
            height: scale+'px',
            left: this.state.posicionX+'px',
            top: this.state.posicionY+'px',
            position: 'absolute',
            transform: 'rotate('+rotation+'deg)'
        }

        //console.log(JSON.stringify(this.positionRoca))

        return(
            <div className = "roca" style = {this.positionRoca}>
                <img src = {r01} title = {this.props.titulo}></img>
            </div>
        );
    }
}

export default Roca;
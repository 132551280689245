import React, {Component} from 'react';

import {Link, NavLink} from 'react-router-dom';

import Ilustracion from './Ilustracion';
import Work from './Work';

class Home extends Component{

    state = {
        height: undefined,
        width: undefined
    }

    handleResize = () => this.setState(
        {
            height: window.innerHeight,
            width: window.innerWidth
        }
    )

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    
    render(){
        return(
            <div
                className = "container"
            >
                <Ilustracion
                    title = 'Ilustracion'
                    heightI = {this.state.height}
                    widthI = {this.state.width}
                />
                <Work
                    title = 'Work'
                />
            </div>
        );
    }
}

export default Home;
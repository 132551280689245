import React, {Component} from 'react';
import Rocas from './Rocas';
import '../css/ilustracion.css';
import '../css/animaciones.css';

import nave01 from '../img/nave_01.png';
import nave02 from '../img/nave_02.png';
import nave03 from '../img/nave_03.png';
import nave04 from '../img/nave_04.png';
import nave05 from '../img/nave_05.png';
import nave06 from '../img/nave_06.png';
import nave07 from '../img/nave_07.png';

import p01 from '../img/i_p01.png';
import p02 from '../img/i_p02.png';
import fp01 from '../img/i_fp01.png';
import fp02 from '../img/i_fp02.png';
import p03 from '../img/i_p03.png';
import e01 from '../img/i_e01.png';

class Ilustracion extends Component {

    widthNave = () => {

        console.log(this.props.widthI);

        if(this.props.widthI < 376){
            return "scale(0.5)"
        }else if(this.props.widthI > 376 && this.props.widthI < 840){
            return "scale(0.8)"
        }else{
            return "scale(1)"
        }
    }

    render(){

        console.log(this.widthNave());
        return(
            <div 
                className = 'ilustracion'
                style = {{height: this.props.heightI + 'px'}}
            >
                <div className = 'p02'>
                    <img src = {fp01} title = "fp01"></img>
                </div>
                <div className = 'p01'>
                    <img src = {fp02} title = "fp02"></img>
                </div>
                <div className = "circulo01"></div>
                <div className = "circulo02"></div>
                <div className = 'p02'>
                    <img src = {p02} title = "p02"></img>
                </div>
                <div className = 'p01'>
                    <img src = {p01} title = "p01"></img>
                </div>
                <div className = 'p03'>
                    <img src = {p03} title = "p03"></img>
                </div>
                <div className = 'e01'>
                    <img src = {e01} title = "e01"></img>
                    <img src = {e01} title = "e01"></img>
                </div>
                <div 
                    className = 'nave'
                    style = {{
                        transform: this.widthNave(),
                        WebkitTransform: this.widthNave(),
                        msTransform: this.widthNave()
                    }}
                >
                    {/* <Rocas titulo = "Rocas"/> */}
                    <div className = "contentNave">
                        <img className = "nave07" src = {nave07} title = "nave"></img>
                        <img className = "nave06" src = {nave06} title = "nave"></img>
                        <img className = "nave05" src = {nave05} title = "nave"></img>
                        <img className = "nave04" src = {nave04} title = "nave"></img>
                        <img className = "nave03" src = {nave03} title = "nave"></img>
                        <img className = "nave02" src = {nave02} title = "nave"></img>
                        <img className = "nave01" src = {nave01} title = "nave"></img>
                    </div>
                    <div className = "humo">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>

                <div className = 'texto'>
                    <p>Besprone is: Marco Antonio De Castilla Vicelis an illustrator, motion designer and user interface designer.</p>
                </div>
            </div>
        );
    }
}

export default Ilustracion;
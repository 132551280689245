import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';

import { ReactComponent as Logo } from '../svg/logo_besprone.svg';
import { ReactComponent as Menu } from '../svg/icono_menu.svg';
import { ReactComponent as VI } from '../svg/icono_vi.svg';
import { ReactComponent as IM } from '../svg/icono_im.svg';
import { ReactComponent as FB } from '../svg/icono_fb.svg';
import { ReactComponent as GI } from '../svg/icono_gi.svg';
import { ReactComponent as Behance } from '../svg/icono_behance.svg';
import { ReactComponent as Vimeo } from '../svg/icono_vimeo.svg';
import { ReactComponent as Dribbble } from '../svg/icono_dribbble.svg';
import { ReactComponent as Mail } from '../svg/icono_mail.svg';
import { ReactComponent as School } from '../svg/icono_School.svg';

import '../css/Header.css';

class Header extends Component{

    handleClick(){
        const wrapper = document.getElementById('wrapper');
        wrapper.classList.toggle('is-nav-open');
        const modal = document.getElementById('modal');
        modal.classList.toggle('modal02');
    }

    render(){
        return(
            <div>
                <header>
                    <h1 className = "titulo">
                        <Menu
                            className = 'menu'
                            titulo = "icono_menu"
                            onClick = {this.handleClick}
                        />
                        <span className = "text_titulo">Besprone</span>
                        <NavLink
                            to = "/"
                        >
                            <Logo
                                className = "logo_besprone"
                                titulo = "Besprone"
                            />
                        </NavLink>
                    </h1>
                    <div className = "school">
                        <NavLink
                            to = "/School"
                        >
                            <School/>
                        </NavLink>
                    </div>
                </header>
                <nav>
                    <div id = "wrapper" className = "menuContent">
                        <ul>
                            <div className = "design">
                                <h2>Menú</h2>
                                <NavLink
                                    to = "/ilustracion-motion"
                                    onClick = {this.handleClick}
                                    className = "inactivo"
                                    activeClassName = "activo"
                                >
                                    <li>
                                        <div><IM /></div>
                                        <p>Illustration + motion</p>
                                    </li>
                                </NavLink>
                                <NavLink
                                    to = "/grafico-identidad"
                                    onClick = {this.handleClick}
                                    className = "inactivo"
                                    activeClassName = "activo"
                                >
                                    <li>
                                        <div><GI /></div>
                                        <p>Graphic design + Branding</p>
                                    </li>
                                </NavLink>
                                <NavLink
                                    to = "/visual-interaccion"
                                    onClick = {this.handleClick}
                                    className = "inactivo"
                                    activeClassName = "activo"
                                >
                                    <li>
                                        <div><VI /></div>
                                        <p>Interaction Design</p>
                                    </li>
                                </NavLink>
                                <NavLink
                                    to = "/front-back"
                                    onClick = {this.handleClick}
                                    className = "inactivo"
                                    activeClassName = "activo"
                                >
                                    <li>
                                        <div><FB /></div>
                                        <p>Front and back</p>
                                    </li>
                                </NavLink>
                            </div>
                            <div className = "social">
                                <h2>Social</h2>
                                <a href = "https://www.behance.net/teporingo/" target = "_blank">
                                    <li>
                                        <div>
                                            <Behance />
                                        </div>
                                        <p>Behance</p>
                                    </li>
                                </a>
                                <a href = "https://vimeo.com/bespro" target = "_blank">
                                    <li>
                                        <div>
                                            <Vimeo />
                                        </div>
                                        <p>Vimeo</p>
                                    </li>
                                </a>
                                <a href = "https://dribbble.com/Besprone" target = "_blank">
                                    <li>
                                        <div>
                                            <Dribbble />
                                        </div>
                                        <p>Dribbble</p>
                                    </li>
                                </a>
                            </div>
                            <div className = "contacto">
                                <h2>Contact</h2>
                                <a href="mailto:besprone@gmail.com?subject=Hello Besprone" target = "_blank">
                                    <li>
                                        <div>
                                            <Mail />
                                        </div>
                                        <p>besprone@gmail.com</p>
                                    </li>
                                </a>
                            </div>
                        </ul>
                    </div>
                </nav>
                <div id = "modal" className = "modal"  onClick = {this.handleClick}></div>
            </div>
        );
    }
}

export default Header;
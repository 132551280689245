import React, {Component} from 'react';
import { ReactComponent as AI } from '../svg/icono_arrow.svg';
import {Link, NavLink} from 'react-router-dom';
import '../css/proyecto.css';

class Proyecto extends Component{

    divs = []

    state = {
        imgs: this.props.proyecto.imagenes,
        img: this.props.proyecto.imagenes[0],
        divs: [],
        div: this.divs[0],
        width: undefined,
        link: ""
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.link();
    }

    componentWillMount(){
        this.divsState();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        let card = document.getElementsByClassName("card_proyecto")[0];
        let cardWidth = card.clientWidth;
        console.log(cardWidth);
        return this.setState({
            width: cardWidth
        })
    }

    tituloVisual(i){
        console.log(this.props.proyecto.categoria);
        switch(this.props.proyecto.categoria){
            case "Diseño de Interacción":
                return this.switche_visual(i)
            case "Illustration + motion graphics":
                return this.switche_ilustracion(i)
            case "Front-end development and back-end":
                return this.switche_frontBack(i)
            case "Graphics design + identidad":
                return this.switche_GraficoIdentidad(i)
        }
    }

    // switche_visual(i){
    //     switch (i) {
    //         case 0: return "Sketch";
    //         case 1: return "Wireframes";
    //         case 2: return "Mockups";
    //         case 3: return "Prototipo";
    //     }
    // }

    switche_visual(i){
        switch (i) {
            case 0: return "Visuales";
            case 1: return "Visuales";
            case 2: return "Visuales";
            case 3: return "Visuales";
        }
    }

    // switche_ilustracion(i){
    //     switch (i) {
    //         case 0: return "Sketch";
    //         case 1: return "Ilustración";
    //         case 2: return "Animación";
    //     }
    // }

    switche_ilustracion(i){
        switch (i) {
            case 0: return "Visuales";
            case 1: return "Visuales";
            case 2: return "Visuales";
        }
    }

    switche_frontBack(i){
        switch (i) {
            case 0: return "Evidencia";
            case 1: return "Evidencia";
            case 2: return "Evidencia";
        }
    }

    switche_GraficoIdentidad(i){
        switch (i) {
            case 0: return "Propuesta";
            case 1: return "Propuesta";
            case 2: return "Propuesta";
        }
    }

    divsState = () => {
        this.state.imgs.map((img, id) => {
            let div = {
                id: id,
                titulo: this.switche_visual(id),
                ruta: require("../img/proyectos/"+img)
            }

            this.divs.push(div)

            return this.setState({
                        divs: this.divs,
                        div: this.divs[0]
                    })
        })
    }

    prevImg = () => {
        const newId = this.state.div.id-1;
        this.setState({
            div: this.state.divs[newId]
        })
    }

    nextImg = () => {
        const newId = this.state.div.id+1;
        this.setState({
            div: this.state.divs[newId]
        })
    }

    link = () => {
        switch(this.props.proyecto.categoria){
            case "Diseño de Interacción":
                this.setState({
                    link: "/visual-interaccion"
                })
                break;
            case "Illustration + motion graphics":
                this.setState({
                    link: "/ilustracion-motion"
                })
                break;
            case "Front-end development and back-end":
                this.setState({
                    link: "/front-back"
                })
                break;
            case "Graphics design + identidad":
                this.setState({
                    link: "/grafico-identidad"
                })
                break;
        }
    }

    render(){

        console.log(this.divs);
        console.log(this.state.divs.length);
        console.log(this.state.div);

        return(
            <div className = "proyecto">
                <h2>
                    <p>{this.props.proyecto.titulo}</p>
                </h2>
                <NavLink
                    to = {this.state.link}
                    className = "inactivo"
                    activeClassName = "activo"
                    style = {{
                        textDecoration: 'none'
                    }}
                >
                    <h3>{"< "+this.props.proyecto.categoria}</h3>
                </NavLink>
                <h4>Descripcción</h4>
                <p>{this.props.proyecto.descripccion}</p>
                <div className = "proceso">
                    <h4>Demo</h4>
                    <div 
                        className = "imgs"
                        style ={{
                            'transform': `translateX(-${this.state.div.id*((this.state.width * this.state.divs.length)/this.state.divs.length)}px)`
                        }}
                    >
                        {
                            this.state.divs.map(div => {
                                return(
                                    <div
                                        key = {div.id}
                                        className = "card_proyecto"
                                    >
                                        <h5>
                                            {this.tituloVisual(div.id)}
                                        </h5>
                                        <img src = {div.ruta} title = {this.tituloVisual(div.id)}></img>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className = "botonera">
                        <div 
                            className = "preview"
                            onClick = {() => {this.prevImg()}}
                            disabled = {
                                this.state.div.id === 0
                            }
                            title = "previous"
                        >
                            <AI/>
                        </div>
                        <div 
                            className = "next"
                            onClick = {() => {this.nextImg()}}
                            disabled = {
                                this.state.div.id === this.state.divs.length-1
                            }
                            title = "next"
                        >
                            <AI/>
                        </div>
                    </div>
                    
                    {this.props.proyecto.demo != null ? <div className="demo">
                        <a className="demo_a" href = {this.props.proyecto.demo} target = "_blank">
                            <p>Demo</p>
                        </a>
                    </div> : null}
                </div>
            </div>
        );
    }
    
}

export default Proyecto;
import React, {Component} from 'react';

import { ReactComponent as BesproneG } from '../svg/G_logo_besprone.svg';
import { ReactComponent as RetratoBes } from '../svg/besprone_retrato.svg';
import e01 from '../img/i_e01.png';

import '../css/footer.css';

class Footer extends Component {
    render(){
        return(
            <footer>
                <div className = "estrellas">
                    <img src = {e01}></img>
                </div>
                <div className = "iconoImg">
                    <div className = "logoBesprone"><RetratoBes title = "Besprone"/></div>
                    <div className = "externos">
                        <h3>Social</h3>
                        <a href = "https://www.behance.net/teporingo/" target = "_blank"><p>Behance</p></a>
                        <a href = "https://vimeo.com/bespro" target = "_blank"><p>Vimeo</p></a>
                        <a href = "https://dribbble.com/Besprone" target = "_blank"><p>Dribbble</p></a>
                        <h3>Contact</h3>
                        <a href="mailto:besprone@gmail.com?Subject=Me%20interesa%20contactarte"><p>besprone@gmail.com</p></a>
                    </div>
                </div>
                <div className = "copy">
                    <p>Copyright © {new Date().getFullYear()} Besprone all rights reserved.</p>
                </div>
            </footer>
        );
    }
}

export default Footer;
import React, {Component} from 'react';
import Roca from './Roca';

class Rocas extends Component {
    render(){
        let rocasss = [];
        
        for (let i = 0; i < 10; i++) {
            rocasss[i] = <Roca titulo = {i}/>;
            //rocasss.push(<Roca titulo = {i}/>);
        }

        return(
            <div className="contentRocas">
                {rocasss}
            </div>
        );
    }
}

export default Rocas;
import React, {Component} from 'react';

import '../css/card.css';

class Card extends Component {

    state = {
        width: undefined
    }

    handleResize = () => {
        let card = document.getElementsByClassName("card")[0];
        let cardWidth = card.clientWidth;
        console.log(cardWidth);
        return this.setState({
            width: cardWidth
        })
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render(){
        return(
            <div 
                style = {{height: this.state.width + "px"}}
                className = {(this.props.numero % 2 == 0 ? 'card par' : 'card impar')} title = {this.props.datos.titulo}
            >
                <h3>{this.props.datos.titulo}</h3>
                <img 
                    src = {require("../img/proyectos/"+this.props.datos.imagenes[0])}
                >
                </img>
            </div>
        )
    }
}
export default Card;



import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import Card from './Card';

import { ReactComponent as FB } from '../svg/icono_fb.svg';
import { ReactComponent as PLUS } from '../svg/icon_plus.svg';

import '../css/visual.css';

class FrontBack extends Component{

    number = 1;

    constructor(props) {
        super(props);

        this.state = {
            frontBack: this.props.frontBack,
            visible: 4
        };

        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        this.setState({
            visible: this.state.visible + 4
        });
    }

    render(){

        const frontBack = this.state.frontBack;
        const visible = this.state.visible;

        return(
            <div className = "visual">
                <h2>
                    <div><FB /></div>
                    <p>Front-end and back-end</p>
                </h2>
                <div className = "cards">
                        {frontBack.slice(0, visible).map(datos => (
                            <Link
                                to = {`/proyecto/${datos.id}`}
                                className = "a"
                            >
                                <Card
                                    key = {datos.id}
                                    datos = {datos}
                                    numero = {this.number++}
                                />
                            </Link>
                        ))}
                </div>
                {/* {visible < frontBack.length &&
                    <button onClick={this.loadMore} type="button" className="load-more">Load more</button>
                } */}

                {visible < frontBack.length ?
                    <button 
                        onClick={this.loadMore} 
                        type="button" 
                        className="load-more">
                            <div><PLUS /></div>
                            <p>CARGAR MÁS</p>
                    </button>
                    : null
                }

            </div>
        )
    }
}

export default FrontBack;
import React, {Component} from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './componentes/Home';
import School from './componentes/School';
import VisualInteraccion from './componentes/VisualInteraccion';
import Proyecto from './componentes/Proyecto';
import IlustracionMotion from './componentes/IlustracionMotion';
import FrontBack from './componentes/FrontBack';
import GraficoIdentidad from './componentes/GraficoIdentidad';
import Header from './componentes/Header';
import Footer from './componentes/Footer';
import ScrollToTop from './componentes/ScrollToTop';

class App extends Component {

  data = require('./json/proyectos.json');
  
  state = {
    s_visual: [],
    s_ilustracionMotion: [],
    s_frontBack: [],
    s_grafico: []
  };

  componentDidMount(){
    document.title = "Besprone";
  }

  componentWillMount(){
    document.title = "Besprone";
    this.mapearPyectos();
    window.scrollTo(0, 0);
  }

  mapearPyectos = () => {
    const dataVisual = this.data.filter(visu_al => visu_al.categoria == "Diseño de Interacción");
    const dataIlustracionMotion = this.data.filter(ilustra_motion => ilustra_motion.categoria == "Illustration + motion graphics");
    const dataFronBack = this.data.filter(front_back => front_back.categoria == "Front-end development and back-end");
    const dataGrafico = this.data.filter(disenio_grafic => disenio_grafic.categoria == "Graphics design + identidad");

    return this.setState({
        s_visual: dataVisual,
        s_ilustracionMotion: dataIlustracionMotion,
        s_frontBack: dataFronBack,
        s_grafico: dataGrafico
      })
  }

  render(){

    // console.log(this.state.s_frontBack);
    // console.log(this.state.s_grafico);
    // console.log(this.state.s_ilustracionMotion);
    // console.log(this.state.s_visual);

    return (
      <Router>
        <ScrollToTop>
          <Header />
          <Switch>
            <Route exact path = "/" component = {Home} />
            <Route exact path = "/school" component = {School} />
            <Route exact path = "/visual-interaccion" 
              render = {() => (
                <VisualInteraccion 
                  visual = {this.state.s_visual}
                />
              )}
            />
            <Route exact path = "/ilustracion-motion"
              render = {() => (
                <IlustracionMotion 
                  ilustraMotion = {this.state.s_ilustracionMotion}
                />
              )}
            />
            <Route exact path = "/front-back"
              render = {() => (
                <FrontBack 
                  frontBack = {this.state.s_frontBack}
                />
              )}
            />
            <Route exact path = "/grafico-identidad" 
              render = {() => (
                <GraficoIdentidad 
                  graficoIdentidad = {this.state.s_grafico}
                />
              )}
            />
            <Route exact path = "/proyecto/:id" 
              render = {props => {
                const idProyecto = parseInt(props.match.params.id);
                let proyecto = "";

                switch(this.data[idProyecto-1].categoria){
                  case "Diseño de Interacción":
                      proyecto = this.state.s_visual.filter(proyecto => proyecto.id === idProyecto);
                      break;
                  case "Illustration + motion graphics":
                      proyecto = this.state.s_ilustracionMotion.filter(proyecto => proyecto.id === idProyecto);
                      break;
                  case "Front-end development and back-end":
                      proyecto = this.state.s_frontBack.filter(proyecto => proyecto.id === idProyecto);
                      break;
                  case "Graphics design + identidad":
                      proyecto = this.state.s_grafico.filter(proyecto => proyecto.id === idProyecto);
                      break;
                }
                
                return(
                  <Proyecto 
                    proyecto = {proyecto[0]}
                  />
                )
              }}
            />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
      
    );
  }

}

export default App;

import React, {Component} from 'react';

import {Link, NavLink} from 'react-router-dom';

import { ReactComponent as GVI } from '../svg/G_icono_vi.svg';
import { ReactComponent as GIM } from '../svg/G_icono_im.svg';
import { ReactComponent as GFB } from '../svg/G_icono_fb.svg';
import { ReactComponent as GGI } from '../svg/G_icono_gi.svg';

import '../css/work.css';

class Work extends Component {
    render(){
        return(
            <div className = "work">
                <h2>Work</h2>
                <div className = "contentMenu">
                    <NavLink
                        to = "/ilustracion-motion"
                        onClick = {this.handleClick}
                        className = "inactivo"
                        activeClassName = "activo"
                    >
                        <div className = "botonMenu">
                            <div className = "g_icon">
                                <GIM/>
                            </div>
                            <p>Illustration + motion graphics</p>
                        </div>
                    </NavLink>
                    <NavLink
                        to = "/grafico-identidad"
                        onClick = {this.handleClick}
                        className = "inactivo"
                        activeClassName = "activo"
                    >
                        <div className = "botonMenu">
                            <div className = "g_icon">
                                <GGI/>
                            </div>
                            <p>Graphic design + branding</p>
                        </div>
                    </NavLink>
                    <NavLink
                        to = "/visual-interaccion"
                        onClick = {this.handleClick}
                        className = "inactivo"
                        activeClassName = "activo"
                    >
                        <div className = "botonMenu">
                            <div className = "g_icon">
                                <GVI/>
                            </div>
                            <p>Visual + Interaction Design</p>
                        </div>
                    </NavLink>
                    <NavLink
                        to = "/front-back"
                        onClick = {this.handleClick}
                        className = "inactivo"
                        activeClassName = "activo"
                    >
                        <div className = "botonMenu">
                            <div className = "g_icon">
                                <GFB/>
                            </div>
                            <p>Front-end and back-end</p>
                        </div>
                    </NavLink>
                </div>
            </div>
        );
    }
}

export default Work;
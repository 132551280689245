import React, {Component} from 'react';
import Perfil from '../img/school.png';

import '../scss/school.scss';

class School extends Component{
    render(){
        return(
            <div className = "content-chool">
                <div className="tarjeta">
                    <div className = "emote">
                        <img src = {Perfil} title = "Besprone"></img>
                    </div>
                    <h2>Marco Antonio De Castilla Vicelis</h2>
                    <h4>Visual/Ilustrador/Front/Motion</h4>
                </div>
                <div className = "parrafos">
                    <div className = "parrafo_01">
                        <h3>Extracto</h3>
                        <p>Hola, soy diseñador y comunicador visual, con orientación en ilustración, cuento con seis años de experiencia, he trabajado en diferentes áreas del diseño, como impresión, animación, web e ilustración. Actualmente estoy estudiando ingeniería en desarrollo de software, con la cual complemento los conocimientos de diseño.</p>
                    </div>
                    <div className = "parrafo_02">
                        <h3>Experiencia</h3>
                        <div className = "x_01">
                            <h4>Nuxiba</h4>
                            <ul>
                                <li>Diseñador UI</li>
                                <li>Enero de 2018 - Presente</li>
                                <li>Diseño visual, motion, ilustración, prototipado.</li>
                            </ul>
                        </div>
                        <div className = "x_01">
                            <h4>Applica2</h4>
                            <ul>
                                <li>Motion designer</li>
                                <li>2013 - enero de 2018 (5 años 1 mes)</li>
                                <li>Diseño de carteles, folletos, diseño editorial de libros, ilustración, diseño web, animación de gráficos, programación web, diseño tipográfico.</li>
                            </ul>
                        </div>
                    </div>
                    <div className = "parrafo_03">
                        <h3>Educación</h3>
                        <div className = "edu_01">
                            <h4>Facultad de Artes y Diseño (UNAM) 2008 - 2013</h4>
                            <p>Licenciatura en diseño y comunicación visual (Ilustración)</p>
                        </div>
                        <div className = "edu_02">
                            <h4>Universidad Abierta y a Distancia de México (SEP) 2016 - 2020</h4>
                            <p>Ingeniería en desarrollo de software</p>
                        </div>
                        <div className = "edu_03">
                            <h4>Facultad de Artes y Diseño (UNAM) 2014 - 2014</h4>
                            <p>Diplomado, Desarrollo y aplicaciones web - HTML5 - CSS3 - JavaScript</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default School;